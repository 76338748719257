import { post } from '@/utils/request'

//获取目录
export function getList(data) {
    return post('psi/warehouse/listStorage', data)
}
//修改
export function getEdit(data) {
    return post('psi/warehouse/editStorage', data)
}
//添加
export function getAdd(data) {
    return post('psi/warehouse/addStorage', data)
}
//删除
export function getDel(data) {
    return post('psi/warehouse/delStorage', data)
}
//分类下拉
export function getOption(data) {
    return post('psi/warehouse/Warehouse', data)
}

//员工列表
export function getUserlist(data) {
    return post('/admin/storage/getUserList', data)
}


// 获取当前用户信息

export function getUserInfo(data) {
    return post('admin/storage/getUserInfo', data)
}

// 获取仓库列表
export function getWarehousesList(data) {
    return post('admin/storage/warehouseList', data)
}

// 获取商品列表

export function getGoodsList(data) {
    return post('admin/storage/goodsList', data)
}
// 获取当天时间
export function getToday(data) {
    return post('admin/storage/getToday', data)
}
// 获取入库编号

export function getStorageNo(data) {
    return post('admin/storage/getStorageNo', data)
}

// 商品申请入库

export function addGoodsToStorage(data) {
    return post('admin/storage/addGoodsToStorage', data)
}
// 仓库商品列表

export function storageGoodsList(data) {
    return post('admin/storage/storageGoodsList', data)
}
// 仓库商品详情
export function storageGoodsInfo(data) {
    return post('admin/storage/storageGoodsInfo', data)
}
// 编辑商品库存

export function editStorageGoods(data) {
    return post('admin/storage/editStorageGoods', data)
}